import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Sikiliza kupitia 92.9mhz - DODOMA",
          "YOUTUBE - A digital",
          "Social Media - Afmradiotz",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 70,
      }}
    />
  );
}

export default Type;
