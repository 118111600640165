// // src/components/ButtonContainer.js
// import React, { useState } from 'react';
// import ReactAudioPlayer from 'react-audio-player';
// import {
//     AiOutlinePlayCircle, AiOutlinePauseCircle, AiOutlineYoutube
// } from "react-icons/ai";
// import './ButtonContainer.css'; // Import a separate CSS file for styling

// const ButtonContainer = () => {
//     const [isPlaying, setIsPlaying] = useState(false);

//     const togglePlayPause = () => {
//       setIsPlaying(!isPlaying);
//     };
  
//     // Define the audio stream URL
//     const audioStreamUrl = 'http://198.7.62.157:10056/stream';
  

//     const openYouTubeChannel = () => {
//         window.open('https://www.youtube.com/@ADigitalTz', '_blank');
//     };

//     return (
//         <div className="button-container">
//              <ReactAudioPlayer
//         src={audioStreamUrl}
//         autoPlay={isPlaying}
//         controls={false} // Hide the player controls
//       />
//             <button className={`button play-button ${isPlaying ? 'paused' : 'Play'}`}
//              onClick={togglePlayPause}>
//                 {isPlaying ? <AiOutlinePauseCircle /> : <AiOutlinePlayCircle />} {isPlaying ? 'Pause Radio' : 'Play Radio'}
//             </button>
//             <button className="button youtube-button" onClick={openYouTubeChannel}>
//                 <AiOutlineYoutube />Watch live
//             </button>
//         </div>
//     );
// };

// export default ButtonContainer;
import React from 'react';
import ReactAplayer from 'react-aplayer';
export default class ButtonContainer extends React.Component {
  // event binding example
  onPlay = () => {
    console.log('on play');
  };

  onPause = () => {
    console.log('on pause');
  };

  // example of access aplayer instance
  onInit = ap => {
    this.ap = ap;
  };

  render() {
    const props = {
      theme: '#ee215f',
   
    fixed: false,autoplay:true,
      audio: [
        {
          name: 'AFM',
          artist: 'RADIO',
          url: 'http://198.7.62.157:10056/stream',
          cover:'afmlogo.jpg',
          lrc: 'great music all day',
          theme: '#ee215f'
        }
      ]
    };

    return (
      <div>
        <ReactAplayer
          {...props}
          onInit={this.onInit}
          onPlay={this.onPlay}
          onPause={this.onPause}
        />
        {/* example of access aplayer instance API */}
      </div>
    
    );
  }
  }