import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          A.FM Radio :    <span className="purple">The Number One Urban </span>
          Radio Station In<span className="purple"> Central Zone</span>
            <br /> Based in DODOMA Capital City.
            <br />
            Media/News company
            <br />
            <br />
          </p>
          {/* <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Partying
            </li>
            <li className="about-activity">
              <ImPointRight /> EDM
            </li>
          </ul> */}
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
